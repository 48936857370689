import * as React from "react";

import Container from "@mui/material/Container";
import Copyright from "../components/Copyright";

import BasicTabs from "../components/BasicTabs";
import Box from "@mui/material/Box";
import { StaticImage } from "gatsby-plugin-image";
import Typography from "@mui/material/Typography";
import { SEO } from "../components/seo";

const IndexPage = () => {
  return (
    // <Container maxWidth="sm">
    //   <Box sx={{ my: 4 }}>
    //     <Typography variant="h4" component="h1" gutterBottom>
    //       Material UI Gatsby example
    //     </Typography>
    //     <Link to="/about" color="secondary">
    //       Go to the about page
    //     </Link>
    //     <ProTip />
    //     <Copyright />
    //   </Box>
    // </Container>
    <Container sx={{ padding: 0 }} maxWidth={false} disableGutters>
      <Container maxWidth="lg">
        <Typography
          variant="body1"
          component="body1"
          align="center"
          paddingTop={2}
        >
          <Box alignContent={"center"} paddingBottom={2}>
            <StaticImage
              src="../images/Reco-Sys-Logo.png"
              alt="Reco System Logo"
              width={200}
              sizes="(min-width: 200px) 100vw, 200px"
            />
          </Box>
          <Box sx={{ borderTop: 1, borderColor: "divider" }}></Box>
        </Typography>
      </Container>

      <Typography align="center">
        <BasicTabs />
      </Typography>
      <br />

      <Box>
        <Copyright />
      </Box>
      <br />
    </Container>
  );
};

export default IndexPage;

export const Head = ({ location, params, data, pageContext }) => <SEO />;
