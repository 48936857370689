import * as React from "react";
import Typography from "@mui/material/Typography";
import MuiLink from "@mui/material/Link";

export default function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <MuiLink color="inherit" href="https://www.reco-system.com">
        Reco System Singapore
      </MuiLink>{" "}
      {new Date().getFullYear()}
      {". Reg. No: 53251882M"}
    </Typography>
  );
}
